
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ formTitle }}</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                    <b-row>
                        <b-col lg="4" md="4" sm="12">
                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{ $t('barc_config.fiscal_year') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="assessmentOther.fiscal_year_id"
                                        :options="fiscalYearList"
                                        id="type_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <h4 class="text-dark" style="font-weight:700;margin-bottom:10px;">{{ $t('barc_config.service_length_score_info') }}</h4>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Point Per Year" vid="point_per_year" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="point_per_year"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{ $t('barc_config.point_per_year') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        plain
                                        v-model="assessmentOther.service_length.point"
                                        id="point_per_year"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <h4 class="text-dark" style="font-weight:700;margin-bottom:10px;">{{ $t('barc_config.age_score_info') }}</h4>
                        </b-col>
                    </b-row>
                    <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px; margin-top:20px;">
                      <b-col lg="3">
                        <p class="text-dark">{{ $t('barc_config.age_range_from') }}</p>
                      </b-col>
                      <b-col lg="3">
                        <p class="text-dark">{{ $t('barc_config.age_range_to') }}</p>
                      </b-col>
                      <b-col lg="3">
                        <p class="text-dark">{{ $t('barc_config.point') }}</p>
                      </b-col>
                      <b-col lg="3">
                        <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                      </b-col>
                    </b-row>
                    <b-row v-for="(age_score, indx) in assessmentOther.age_score_info" :key="indx" style="margin-bottom:20px">
                        <b-col xl="3" lg="3" sm="6">
                            <ValidationProvider name="Age Range From" vid="age_range_from" rules="required|min_value:1">
                                <b-form-group
                                    label-for="age_range_from"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        plain
                                        v-model="age_score.age_range_from"
                                        id="class_or_grade_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="3" lg="3" sm="6">
                            <ValidationProvider name="Age Range To" vid="age_range_to" rules="required|min_value:1">
                                <b-form-group
                                    label-for="age_range_to"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        plain
                                        v-model="age_score.age_range_to"
                                        id="age_range_to"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="3" lg="3" sm="6">
                            <ValidationProvider name="Point" rules="required|min_value:1">
                                <b-form-group
                                    label-for="point"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        v-model="age_score.point"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="3" lg="3" sm="6">
                            <b-button v-if="indx == assessmentOther.age_score_info.length-1" variant=" iq-bg-success" size="sm" @click="ageScoreAdd()"><i class="ri-add-line m-0"></i></b-button>
                            <b-button v-show="indx || ( !indx && assessmentOther.age_score_info.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeAgeScore(indx)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <h4 class="text-dark" style="font-weight:700;margin-bottom:10px;">{{ $t('barc_config.acr_report_info') }}</h4>
                        </b-col>
                    </b-row>
                    <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px; margin-top:20px;">
                      <b-col lg="5">
                        <p class="text-dark">{{ $t('barc_config.performance_evaluation_type') }}</p>
                      </b-col>
                      <b-col lg="5">
                        <p class="text-dark">{{ $t('barc_config.point') }}</p>
                      </b-col>
                      <b-col lg="2">
                        <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                      </b-col>
                    </b-row>
                    <b-row v-for="(acr, indx) in assessmentOther.acr_score_info" :key="indx" style="margin-bottom:20px">
                        <b-col xl="5" lg="5" sm="6">
                            <ValidationProvider name="Performance Evaluation Type" vid="type_id" rules="required|min_value:1">
                                <b-form-group
                                    label-for="type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-select
                                        plain
                                        v-model="acr.type_id"
                                        id="type_id"
                                        :options="acrTypes"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="5" lg="5" sm="6">
                            <ValidationProvider name="Point" rules="required|min_value:1">
                                <b-form-group
                                    label-for="point"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        v-model="acr.point"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="2" lg="2" sm="6">
                            <b-button v-if="indx == assessmentOther.acr_score_info.length-1 && assessmentOther.acr_score_info.length < 3" variant=" iq-bg-success" size="sm" @click="acrScoreAdd()"><i class="ri-add-line m-0"></i></b-button>
                            <b-button v-show="indx || ( !indx && assessmentOther.acr_score_info.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeAcrScore(indx)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <h4 class="text-dark" style="font-weight:700;margin-bottom:10px;">{{ $t('barc_config.publication_score_info') }}</h4>
                        </b-col>
                    </b-row>
                    <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px; margin-top:20px;">
                      <b-col lg="5">
                        <p class="text-dark">{{ $t('barc_config.author_type') }}</p>
                      </b-col>
                      <b-col lg="5">
                        <p class="text-dark">{{ $t('barc_config.point') }}</p>
                      </b-col>
                      <b-col lg="2">
                        <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                      </b-col>
                    </b-row>
                    <b-row v-for="(publication, indx) in assessmentOther.publication_score_info" :key="indx" style="margin-bottom:20px">
                        <b-col xl="5" lg="5" sm="6">
                            <ValidationProvider name="Author Type" vid="type_id" rules="required|min_value:1">
                                <b-form-group
                                    label-for="type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-select
                                        plain
                                        v-model="publication.type_id"
                                        id="type_id"
                                        :options="authorTypes"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="5" lg="5" sm="6">
                            <ValidationProvider name="Point" rules="required|min_value:1">
                                <b-form-group
                                    label-for="point"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        v-model="publication.point"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="2" lg="2" sm="6">
                            <b-button v-if="indx == assessmentOther.publication_score_info.length-1" variant=" iq-bg-success" size="sm" @click="publicationScoreAdd()"><i class="ri-add-line m-0"></i></b-button>
                            <b-button v-show="indx || ( !indx && assessmentOther.publication_score_info.length > 1)" variant=" iq-bg-danger" size="sm" @click="removePublicationScore(indx)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <h4 class="text-dark" style="font-weight:700;margin-bottom:10px;">{{ $t('barc_config.scientific_technical_seminar_score_info') }}</h4>
                        </b-col>
                    </b-row>
                    <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px; margin-top:20px;">
                      <b-col lg="5">
                        <p class="text-dark">{{ $t('barc_config.author_type') }}</p>
                      </b-col>
                      <b-col lg="5">
                        <p class="text-dark">{{ $t('barc_config.point') }}</p>
                      </b-col>
                      <b-col lg="2">
                        <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                      </b-col>
                    </b-row>
                    <b-row v-for="(scientific, indx) in assessmentOther.scientific_score_info" :key="indx" style="margin-bottom:20px">
                        <b-col xl="5" lg="5" sm="6">
                            <ValidationProvider name="Author Type" vid="type_id" rules="required|min_value:1">
                                <b-form-group
                                    label-for="type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-select
                                        plain
                                        v-model="scientific.type_id"
                                        id="type_id"
                                        :options="authorTypes"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="5" lg="5" sm="6">
                            <ValidationProvider name="Point" rules="required|min_value:1">
                                <b-form-group
                                    label-for="point"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        v-model="scientific.point"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="2" lg="2" sm="6">
                            <b-button v-if="indx == assessmentOther.scientific_score_info.length-1" variant=" iq-bg-success" size="sm" @click="scientificScoreAdd()"><i class="ri-add-line m-0"></i></b-button>
                            <b-button v-show="indx || ( !indx && assessmentOther.scientific_score_info.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeScientificScore(indx)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <h4 class="text-dark" style="font-weight:700;margin-bottom:10px;">{{ $t('barc_config.training_score_info') }}</h4>
                        </b-col>
                    </b-row>
                    <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px; margin-top:20px;">
                      <b-col lg="5">
                        <p class="text-dark">{{ $t('barc_config.training_title') }}</p>
                      </b-col>
                      <b-col lg="5">
                        <p class="text-dark">{{ $t('barc_config.point') }}</p>
                      </b-col>
                      <b-col lg="2">
                        <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                      </b-col>
                    </b-row>
                    <b-row v-for="(training, indx) in assessmentOther.training_score_info" :key="indx" style="margin-bottom:20px">
                        <b-col xl="5" lg="5" sm="6">
                            <ValidationProvider name="Training Title" vid="type_id" rules="required|min_value:1">
                                <b-form-group
                                    label-for="type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-select
                                        plain
                                        v-model="training.type_id"
                                        id="type_id"
                                        :options="trainingTypes"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="5" lg="5" sm="6">
                            <ValidationProvider name="Point" rules="required|min_value:1">
                                <b-form-group
                                    label-for="point"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        v-model="training.point"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="2" lg="2" sm="6">
                            <b-button v-if="indx == assessmentOther.training_score_info.length-1" variant=" iq-bg-success" size="sm" @click="trainingScoreAdd()"><i class="ri-add-line m-0"></i></b-button>
                            <b-button v-show="indx || ( !indx && assessmentOther.training_score_info.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeTrainingScore(indx)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
            </template>
        </iq-card>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { selfScoreAssessmentOtherStore, selfScoreAssessmentOtherUpdate, selfScoreAssessmentOtherShow } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            totalPost: 0,
            saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            assessmentOther: {
                fiscal_year_id: 0,
                service_length: {
                    point: ''
                },
                age_score_info: [
                    {
                        age_range_from: '',
                        age_range_to: '',
                        point: ''
                    }
                ],
                acr_score_info: [
                    {
                        category: 1,
                        type_id: 0,
                        point: ''
                    }
                ],
                publication_score_info: [
                    {
                        category: 2,
                        type_id: 0,
                        point: ''
                    }
                ],
                scientific_score_info: [
                    {
                        category: 3,
                        type_id: 0,
                        point: ''
                    }
                ],
                training_score_info: [
                    {
                        category: 4,
                        type_id: 0,
                        point: ''
                    }
                ]
            },
            acrTypes: [
                {
                    value: 1,
                    text: this.$t('barc_config.extra_ordinary')
                },
                {
                    value: 2,
                    text: this.$t('barc_config.very_good')
                },
                {
                    value: 3,
                    text: this.$t('barc_config.good')
                }
            ],
            authorTypes: [
                {
                    value: 1,
                    text: this.$t('barc_config.principal_type')
                },
                {
                    value: 2,
                    text: this.$t('barc_config.associate_type')
                }
            ],
            trainingTypes: [
                {
                    value: 1,
                    text: this.$t('barc_config.foundation_training')
                },
                {
                    value: 2,
                    text: this.$t('barc_config.others_training')
                }
            ],
            divisionList: [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'প্রথম বিভাগ' : 'First Division'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'দ্বিতীয় বিভাগ' : 'Second Division'
                },
                {
                    value: 3,
                    text: this.$i18n.locale === 'bn' ? 'তৃতীয় বিভাগ' : 'Third Division'
                }
            ],
            classList: [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'প্রথম শ্রেণী' : 'First Class'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'দ্বিতীয় শ্রেণী' : 'Second Class'
                },
                {
                    value: 3,
                    text: this.$i18n.locale === 'bn' ? 'তৃতীয় শ্রেণী' : 'Third Class'
                }
            ],
            gradeList: [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'এ+' : 'A+'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'এ' : 'A'
                },
                {
                    value: 3,
                    text: this.$i18n.locale === 'bn' ? 'এ-' : 'A-'
                },
                {
                    value: 4,
                    text: this.$i18n.locale === 'bn' ? 'বি' : 'B'
                },
                {
                    value: 5,
                    text: this.$i18n.locale === 'bn' ? 'সি' : 'C'
                },
                {
                    value: 6,
                    text: this.$i18n.locale === 'bn' ? 'ডি' : 'D'
                },
                {
                    value: 7,
                    text: this.$i18n.locale === 'bn' ? 'এফ' : 'F'
                }
            ]
        }
    },
    computed: {
        formTitle () {
            return this.$route.query.id ? this.$t('barc_config.self_score_assessment_other') + ' ' + this.$t('globalTrans.modify') : this.$t('barc_config.self_score_assessment_other') + ' ' + this.$t('globalTrans.entry')
        },
        fiscalYearList: function () {
            return this.$store.state.commonObj.fiscalYearList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        typeList: function () {
            return this.type_id.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        EducationLevelList: function () {
            const objectData = this.$store.state.incentiveGrant.commonObj.educationLevelList
            return objectData.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
    },
    created () {
        const id = this.$route.query.id
        if (id) {
            this.getSelfScoreAssessmentData(id)
        }
    },
    mounted () {
        core.index()
    },
    methods: {
        getSelfScoreAssessmentData (id) {
            RestApi.getData(incentiveGrantServiceBaseUrl, selfScoreAssessmentOtherShow + '/' + id).then(response => {
                if (response.success) {
                    this.assessmentOther = response.data
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            const id = this.$route.query.id

            if (id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${selfScoreAssessmentOtherUpdate}/${id}`, this.assessmentOther)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, selfScoreAssessmentOtherStore, this.assessmentOther)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$router.push({ path: '/incentive-grant-service/barc/configuration/self-score-assessment-others' })
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        ageScoreAdd () {
            const tmpAgeScore = {
                age_range_from: '0',
                age_range_to: '0',
                point: ''
            }
            const key1 = parseInt(this.assessmentOther.age_score_info.length - 1)
            const item = this.assessmentOther.age_score_info[key1]
            let isEmpty = true
            Object.keys(item).map(key => {
                if (item[key] === '' || item[key] === 0) {
                    isEmpty = false
                }
            })
            if (isEmpty === true) {
                this.assessmentOther.age_score_info.push(tmpAgeScore)
            }
        },
        removeAgeScore (key) {
            this.assessmentOther.age_score_info.splice(key, 1)
        },
        acrScoreAdd () {
            const tmpAcrScore = {
                category: 1,
                type_id: 0,
                point: 0
            }
            const key1 = parseInt(this.assessmentOther.acr_score_info.length - 1)
            const item = this.assessmentOther.acr_score_info[key1]
            let isEmpty = true
            Object.keys(item).map(key => {
                if (item[key] === '' || item[key] === 0) {
                    isEmpty = false
                }
            })
            if (isEmpty === true) {
                this.assessmentOther.acr_score_info.push(tmpAcrScore)
            }
        },
        removeAcrScore (key) {
            this.assessmentOther.acr_score_info.splice(key, 1)
        },
        publicationScoreAdd () {
            const tmpPublicationScore = {
                category: 2,
                type_id: 0,
                point: 0
            }
            const key1 = parseInt(this.assessmentOther.publication_score_info.length - 1)
            const item = this.assessmentOther.publication_score_info[key1]
            let isEmpty = true
            Object.keys(item).map(key => {
                if (item[key] === '' || item[key] === 0) {
                    isEmpty = false
                }
            })
            if (isEmpty === true) {
                this.assessmentOther.publication_score_info.push(tmpPublicationScore)
            }
        },
        removePublicationScore (key) {
            this.assessmentOther.publication_score_info.splice(key, 1)
        },
        scientificScoreAdd () {
            const tmpScientificScore = {
                category: 3,
                type_id: 0,
                point: 0
            }
            const key1 = parseInt(this.assessmentOther.scientific_score_info.length - 1)
            const item = this.assessmentOther.scientific_score_info[key1]
            let isEmpty = true
            Object.keys(item).map(key => {
                if (item[key] === '' || item[key] === 0) {
                    isEmpty = false
                }
            })
            if (isEmpty === true) {
                this.assessmentOther.scientific_score_info.push(tmpScientificScore)
            }
        },
        removeScientificScore (key) {
            this.assessmentOther.publication_score_info.splice(key, 1)
        },
        trainingScoreAdd () {
            const tmpTrainingScore = {
                category: 4,
                type_id: 0,
                point: 0
            }
            const key1 = parseInt(this.assessmentOther.training_score_info.length - 1)
            const item = this.assessmentOther.training_score_info[key1]
            let isEmpty = true
            Object.keys(item).map(key => {
                if (item[key] === '' || item[key] === 0) {
                    isEmpty = false
                }
            })
            if (isEmpty === true) {
                this.assessmentOther.training_score_info.push(tmpTrainingScore)
            }
        },
        removeTrainingScore (key) {
            this.assessmentOther.publication_score_info.splice(key, 1)
        }
    }
}
</script>
